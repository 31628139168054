<template>
  <Login />
</template>

<script>
import Login from '@/components/Login/Login.vue'

export default {
  name: 'HomeView',
  components: {
    Login
  },
  mounted() {
    if (indexedDB.databases) {
      indexedDB.databases().then(databases => {
        databases.forEach(db => {
          const dbName = db.name;
          if (dbName) {
            let deleteRequest = indexedDB.deleteDatabase(dbName);

            deleteRequest.onsuccess = function() {
              console.log(`Database ${dbName} deleted successfully.`);
            };

            deleteRequest.onerror = function(event) {
              console.error(`Error deleting database ${dbName}:`, event);
            };

            deleteRequest.onblocked = function(event) {
              console.warn(`Delete request blocked for database ${dbName}.`, event);
            };
          }
        });
      }).catch(error => {
        console.error('Error listing databases:', error);
      });
    } else {
      console.warn('indexedDB.databases() is not supported in this browser.');
    }
    console.log(localStorage.getItem('isLogin'))
    if (localStorage.getItem('isLogin') === 'true') {
      window.location.href = '/dashboard'
    }
  }
}
</script>
